import React, { useRef, useEffect, useState, useCallback } from 'react';
import Form, {
  SimpleItem,
  GroupItem,
  Label,
  Item,
  RequiredRule,
  ButtonItem,
  ButtonOptions,
  CustomRule,
  PatternRule,
  EmailRule,
} from 'devextreme-react/form';
import 'devextreme-react/text-area';
import './season-ticket-member-form.scss';
import { ticket } from '../../../../api';
import { Button } from 'devextreme-react/button';
import { sortByKor } from 'utils/common';
import { Tooltip } from 'devextreme-react/tooltip';
import { tradeUsageType, useDefaultInfo } from 'common/select/Types';
import { days } from 'constants/timeType';
import CustomInfoPopup from 'pages/sales-mng/sales-taxinvoice/popup/CustomInfoPopup';
import notify from 'devextreme/ui/notify';
import { ScrollView } from 'devextreme-react';

const SeasonTicketMemberForm = ({
  setPopup,
  isUpdate,
  formData,
  setIsRefresh,
  selectItemCode,
  groupType,
  popup,
  isRefresh,
  setLoadPanelVisible,
}) => {
  const formRef = useRef({});
  const customInfoRef = useRef({});
  const [selectType, setSelectType] = useState();
  const [selectStatus, setSelectStatus] = useState();
  const [selectDivision, setSelectDivision] = useState();
  const [selectUseYN, setSelectUseYN] = useState();
  const [selectMemberGroup, setSelectMemberGroup] = useState();
  const [isTagActive, setIsTagActive] = useState(false);
  const [selectedCustomInfo, setSelectedCustomInfo] = useState([]);
  const [isUsedCashbill, setIsUsedCashbill] = useState(false);
  const [isUsedAutoIssue, setIsUsedAutoIssue] = useState(false);

  const [updateForm, setUpdateForm] = useState({
    CarType: '',
    CostCenterCode: '',
    CostCenterName: '',
    EmailAddress: '',
    GroupCode: '',
    GroupName: '',
    MemberDivision: '',
    MemberDivisionName: '',
    MemberID: '',
    MemberName: '',
    MemberStatusName: '',
    MemberType: '',
    MemberTypeName: '',
    MobliePhoneNo: '',
    RegisterDate: '',
    RegisterUser: '',
    Status: '',
    TagRentPrice: 0,
    TelephoneNo: '',
    UseTag: '',
    UseTagName: '',
    VehicleRegistration: '',
    StandardPrice: 0,
    //세금계산서 추가
    CustomerCorpRegNumber: '',
    CustomerCorpName: '',
    NameOfRepresentative: '',
    CustomerCode: '',
    DefaultTradeUsage: '',
    DefaultIdentifierNo: '',
    UseDefaultInfo: { data: { 0: false } },
    UseAutoIssue: false,
    AutoPaymentRequestDDay: 0,
  });

  useEffect(() => {
    if (selectedCustomInfo.length > 0) {
      setUpdateForm({
        ...updateForm,
        CustomerCorpRegNumber: selectedCustomInfo[0].CorpRegNumber,
        CustomerCorpName: selectedCustomInfo[0].CustomerCorpName,
        NameOfRepresentative: selectedCustomInfo[0].NameOfRepresentative,
        CustomerCode: selectedCustomInfo[0].CustomerNo,
      });
    }
  }, [selectedCustomInfo]);

  useEffect(() => {
    (async () => {
      const result = await ticket.searchCodeMaster({
        CodeGroup: ['MemberType', 'MemberStatus', 'UseYN', 'MemberDivision'],
      });
      const MemberType = [];
      const MemberStatus = [];
      const UseYN = [];
      const MemberDivision = [];
      if (result.isOk && result.data.length > 0) {
        result.data.forEach(a => {
          if (a.CodeGroup === 'MemberType') {
            MemberType.push({
              text: a.CodeDesc,
              codeDesc: a.CodeDesc,
              code: a.Code,
            });
          } else if (a.CodeGroup === 'MemberStatus') {
            MemberStatus.push({
              text: a.CodeDesc,
              codeDesc: a.CodeDesc,
              code: a.Code,
            });
          } else if (a.CodeGroup === 'UseYN') {
            UseYN.push({
              text: a.CodeDesc,
              codeDesc: a.CodeDesc,
              code: a.Code,
            });
          } else if (a.CodeGroup === 'MemberDivision') {
            return MemberDivision.push({
              text: a.CodeDesc,
              codeDesc: a.CodeDesc,
              code: a.Code,
            });
          }
        });
      }
      setSelectType(MemberType);
      setSelectStatus(MemberStatus);
      setSelectUseYN(UseYN);
      setSelectDivision(MemberDivision);
    })();
  }, []);

  useEffect(() => {
    if (popup.visible) {
      const rawData = groupType.map(a => {
        return {
          text: a.GroupName,
          codeDesc: a.GroupName,
          code: a.GroupCode,
        };
      });
      rawData.unshift('');
      setSelectMemberGroup(sortByKor(rawData, 'codeDesc'));
      // 부모 데이터와 분리, 팝업 그려질때마다 새롭게 셋팅
      const newObj = Object.assign({}, formData);
      setUpdateForm(newObj);
    }
    if (formData) {
      formData.UseTag === 'N' ? setIsTagActive(false) : setIsTagActive(true);
      setIsUsedAutoIssue(formData.UseAutoIssue);
    }
  }, [popup.visible]);

  const onSave = () => {
    const formInstance = formRef.current.instance;
    const res = formInstance.validate();
    if (!res.isValid) {
      return notify(
        {
          message: '입력값을 확인해주세요',
          width: 230,
          position: {
            at: 'top',
            my: 'top',
            of: '#container',
          },
        },
        'error',
      );
    } else {
      onSubmit();
    }
  };

  const onSubmit = useCallback(
    async e => {
      const {
        CostCenterCode,
        MemberID,
        MemberName,
        MemberType,
        Status,
        VehicleRegistration,
        TelephoneNo,
        MobliePhoneNo,
        CarType,
        EmailAddress,
        RegisterUser,
        GroupCode,
        MemberDivision,
        UseTag,
        TagRentPrice,
        StandardPrice,
        CustomerCode,
        DefaultTradeUsage,
        DefaultIdentifierNo,
        DefaultEmailAddress,
        UseDefaultInfo,
        UseAutoIssue,
        AutoPaymentRequestDDay,
      } = formRef.current.props.formData;
      const useDefaultInfo = UseDefaultInfo.data[0];
      const useAutoIssue = UseAutoIssue ? 'Y' : 'N';
      const autoPaymentRequestDDay = parseInt(AutoPaymentRequestDDay, 10);
      const splitVehicleNumber = (fullnameStr, type) => {
        var result = '';
        if (type === 'prefix') result = fullnameStr.substr(0, fullnameStr.length - 4);
        else result = fullnameStr.substr(fullnameStr.length - 4, 4);
        return result.trim();
      };
      setLoadPanelVisible(true);
      if (isUpdate) {
        const result = await ticket.updateSeasonTicketMemberMaster({
          CostCenterCode,
          MemberID,
          MemberName,
          MemberType,
          Status,
          VehicleRegistrationPlatePrefix: splitVehicleNumber(VehicleRegistration, 'prefix'),
          VehicleRegistrationPlateNo: splitVehicleNumber(VehicleRegistration, 'plate'),
          TelephoneNo,
          MobliePhoneNo,
          CarType,
          EmailAddress,
          RegisterUser,
          GroupCode,
          MemberDivision,
          UseTag,
          TagRentPrice,
          StandardPrice,
          CustomerCode,
          DefaultTradeUsage,
          DefaultIdentifierNo,
          DefaultEmailAddress,
          UseDefaultInfo: useDefaultInfo,
          UseAutoIssue: useAutoIssue,
          AutoPaymentRequestDDay: autoPaymentRequestDDay,
        });
        if (result.isOk) {
          setLoadPanelVisible(false);
          notify(
            {
              message: '수정 되었습니다.',
              width: 230,
              position: {
                at: 'top',
                my: 'top',
                of: '#container',
              },
            },
            'success',
          );
          setPopup({ save: true, visible: false });
        } else {
          setLoadPanelVisible(false);
          notify(
            {
              message: JSON.parse(result.error.detail).sqlMessage,
              width: 230,
              position: {
                at: 'top',
                my: 'top',
                of: '#container',
              },
            },
            'error',
          );
        }
      } else {
        const result = await ticket.insertSeasonTicketMemberMaster({
          CostCenterCode: selectItemCode.CostCenterCode,
          MemberName,
          MemberType,
          Status,
          VehicleRegistrationPlatePrefix: splitVehicleNumber(VehicleRegistration, 'prefix'),
          VehicleRegistrationPlateNo: splitVehicleNumber(VehicleRegistration, 'plate'),
          TelephoneNo,
          MobliePhoneNo,
          CarType,
          EmailAddress,
          RegisterUser,
          GroupCode,
          MemberDivision,
          UseTag,
          TagRentPrice,
          StandardPrice,
          CustomerCode,
          DefaultTradeUsage,
          DefaultEmailAddress,
          DefaultIdentifierNo,
          UseDefaultInfo: useDefaultInfo,
          UseAutoIssue: useAutoIssue,
          AutoPaymentRequestDDay: autoPaymentRequestDDay,
        });
        if (result.isOk) {
          setLoadPanelVisible(false);
          notify(
            {
              message: '추가 되었습니다.',
              width: 230,
              position: {
                at: 'top',
                my: 'top',
                of: '#container',
              },
            },
            'success',
          );
          setPopup({ save: true, visible: false });
        } else {
          setLoadPanelVisible(false);
          notify(
            {
              message: JSON.parse(result.error.detail).sqlMessage,
              width: 230,
              position: {
                at: 'top',
                my: 'top',
                of: '#container',
              },
            },
            'error',
          );
        }
      }
      setIsRefresh(!isRefresh);
    },
    [isUpdate, selectItemCode, isRefresh],
  );

  const onClose = () => {
    setSelectedCustomInfo([]);
    setUpdateForm([]);
    setPopup({ save: false, visible: false });
    setIsTagActive(false);
    setIsUsedCashbill(false);
    setIsUsedAutoIssue(false);
  };

  const onItemClick = e => {
    e.itemData.code === 'N' ? setIsTagActive(false) : setIsTagActive(true);
  };

  const onGroupItemClick = e => {
    if (e.itemData) {
      (async () => {
        const groupCode = e.itemData.code;
        const result = await ticket.searchSeasonTicketMemberGroupByGroupID({ GroupCode: groupCode });
        if (result.isOk > 0 && result.data) {
          const code = result.data[0].MemberDivision;
          setUpdateForm({ ...updateForm, MemberDivision: code });
        }
      })();
    } else {
      setUpdateForm({ ...updateForm, MemberDivision: '' });
    }
  };

  const digitCheck = useCallback(({ value }) => {
    var digitPart = value.substr(value.length - 4, 4).trim();
    if (!isNaN(digitPart) && digitPart.length === 4) {
      return true;
    }
  }, []);

  const onFocusIn = e => {
    e.event.target.blur();
  };
  const onValueChanged = e => {
    const value = e.value;
    setIsUsedCashbill(value);
  };
  const onAutoIssueValueChanged = e => {
    if (e.event) {
      setIsUsedAutoIssue(e.value);
    }
  };
  return (
    <>
      <form onSubmit={onSubmit}>
        <div className="form-container season-ticket-form">
          <ScrollView width="100%" height={620}>
            <Form
              ref={formRef}
              formData={updateForm}
              showColonAfterLabel={false}
              labelMode="outside"
              optionalMark="optional"
              stylingMode="outlined"
              labelLocation="left"
            >
              <ButtonItem>
                <ButtonOptions width={'3%'} icon="close" onClick={onClose}></ButtonOptions>
              </ButtonItem>
              <GroupItem caption={isUpdate ? '정기권 회원 변경' : '정기권 회원 추가'} colCount={6}>
                <SimpleItem dataField="CostCenterName" colSpan={3} editorOptions={{ readOnly: true }}>
                  <Label text="사업소명" />
                </SimpleItem>
                <Item
                  dataField="Status"
                  editorType="dxSelectBox"
                  editorOptions={{
                    items: selectStatus,
                    displayExpr: 'text',
                    valueExpr: 'code',
                  }}
                  colSpan={3}
                >
                  <Label text="회원 상태" />
                  <RequiredRule message="필수값 입니다." />
                </Item>
                <SimpleItem dataField="MemberName" colSpan={3}>
                  <Label text="회원명" />
                  <RequiredRule message="필수값 입니다." />
                </SimpleItem>
                <Item
                  dataField="MemberType"
                  editorType="dxSelectBox"
                  editorOptions={{
                    items: selectType,
                    displayExpr: 'text',
                    valueExpr: 'code',
                  }}
                  colSpan={3}
                >
                  <Label text="정기권 유형" />
                  <RequiredRule message="필수값 입니다." />
                </Item>
                <Item
                  dataField="GroupCode"
                  editorType="dxSelectBox"
                  editorOptions={{
                    items: selectMemberGroup,
                    displayExpr: 'text',
                    valueExpr: 'code',
                    onItemClick: onGroupItemClick,
                  }}
                  colSpan={3}
                >
                  <Label text="회원 그룹" />
                </Item>
                <Item
                  dataField="MemberDivision"
                  editorType="dxSelectBox"
                  editorOptions={{
                    items: selectDivision,
                    displayExpr: 'text',
                    valueExpr: 'code',
                  }}
                  colSpan={3}
                >
                  <Label text="회원 구분" />
                  <RequiredRule message="필수값 입니다." />
                </Item>
                <SimpleItem editorType="dxTextBox" dataField="VehicleRegistration" colSpan={3}>
                  <Label text="차량번호" />
                  <RequiredRule message="필수값 입니다." />
                  <CustomRule message={'차량번호 네 자리를 확인해주세요.'} validationCallback={digitCheck} />
                </SimpleItem>
                <SimpleItem dataField="MobliePhoneNo" colSpan={3}>
                  <RequiredRule message="필수값 입니다." />
                  <Label text="핸드폰번호" />
                </SimpleItem>
                <SimpleItem
                  dataField="StandardPrice"
                  colSpan={3}
                  editorType="dxNumberBox"
                  editorOptions={{
                    showSpinButtons: true,
                    step: 1000,
                    min: 0,
                    format: '#,##0 원',
                  }}
                >
                  <Label text="기준금액" />
                </SimpleItem>
                <SimpleItem dataField="EmailAddress" colSpan={3}>
                  <Label text="이메일" />
                </SimpleItem>
                <SimpleItem dataField="TelephoneNo" colSpan={3}>
                  <Label text="전화번호" />
                </SimpleItem>
                <SimpleItem dataField="CarType" colSpan={3}>
                  <Label text="차종" />
                </SimpleItem>
              </GroupItem>
              <GroupItem caption="현금영수증 자동 발행" colCount={6}>
                <SimpleItem
                  colSpan={3}
                  dataField="UseDefaultInfo.data[0]"
                  editorType="dxCheckBox"
                  editorOptions={{
                    items: useDefaultInfo,
                    valueExpr: 'value',
                    layout: 'horizontal',
                    text: `자동 발행 정보 직접 입력하기`,
                    hint: '자동 발행 기본값은 자진발급번호(010-000-1234)입니다.',
                    onValueChanged: onValueChanged,
                  }}
                >
                  <Label text="정보 사용여부" visible={false}></Label>
                </SimpleItem>
                <SimpleItem
                  dataField="DefaultTradeUsage"
                  colSpan={3}
                  editorType="dxRadioGroup"
                  shouldRender="condition"
                  editorOptions={{
                    items: tradeUsageType,
                    valueExpr: 'value',
                    displayExpr: 'label',
                    layout: 'horizontal',
                    disabled: !isUsedCashbill,
                  }}
                >
                  <Label text="거래구분" />
                </SimpleItem>
                <SimpleItem
                  cssClass="textbox-with-button"
                  dataField="DefaultIdentifierNo"
                  editorType="dxTextBox"
                  colSpan={3}
                  editorOptions={{
                    maxLength: 14,
                    disabled: !isUsedCashbill,
                    placeholder: '휴대전화/사업자등록번호',
                  }}
                >
                  <PatternRule pattern={/^[\d-]+(\s+)?$/} message={'숫자와 - 기호만 입력 가능합니다.'} />
                  <Label text="식별번호" />
                </SimpleItem>
                <SimpleItem
                  cssClass="textbox-with-button"
                  dataField="DefaultEmailAddress"
                  editorType="dxTextBox"
                  colSpan={3}
                  editorOptions={{
                    disabled: !isUsedCashbill,
                    placeholder: '현금영수증을 전송할 이메일을 입력하세요.',
                  }}
                >
                  <EmailRule message={'이메일 형식이 올바르지 않습니다.'} />
                  <Label text="E-mail" />
                </SimpleItem>
              </GroupItem>
              <GroupItem caption="세금계산서 정보" colCount={6}>
                <SimpleItem
                  dataField="CustomerCorpRegNumber"
                  editorType="dxTextBox"
                  colSpan={3}
                  editorOptions={{
                    onFocusIn: onFocusIn,
                    buttons: [
                      {
                        name: 'trash',
                        location: 'after',
                        options: {
                          stylingMode: 'text',
                          text: '사업자 선택',
                          onClick: () => {
                            customInfoRef.current.open();
                          },
                        },
                      },
                    ],
                    value: updateForm.CustomerCorpRegNumber,
                  }}
                >
                  <Label text="사업자등록번호" />
                </SimpleItem>
                <SimpleItem
                  dataField="CustomerCorpName"
                  editorType="dxTextBox"
                  colSpan={3}
                  editorOptions={{
                    readOnly: true,
                    value: updateForm.CustomerCorpName,
                  }}
                >
                  <Label text="회사명" />
                </SimpleItem>
                <SimpleItem
                  dataField="NameOfRepresentative"
                  editorType="dxTextBox"
                  colSpan={3}
                  editorOptions={{
                    readOnly: true,
                    value: updateForm.NameOfRepresentative,
                  }}
                >
                  <Label text="대표자 성명" />
                </SimpleItem>
              </GroupItem>
              <GroupItem caption="정기권 자동 발행" colCount={2}>
                <SimpleItem
                  colSpan={1}
                  dataField="UseAutoIssue"
                  editorType="dxCheckBox"
                  editorOptions={{
                    // items: useNYInfo,
                    layout: 'horizontal',
                    text: `정기권 자동 결제 등록하기`,
                    onValueChanged: onAutoIssueValueChanged,
                  }}
                >
                  <Label visible={false} />
                </SimpleItem>
                <SimpleItem editorType="dxTextBox" cssClass="empty-item">
                  <Label text="* 이번 달 통합결제 완료 이력 있고 익월 정기권이 없는 경우" cssClass="padding-top-label" encodeHtml={false} />
                </SimpleItem>
                <SimpleItem
                  colSpan={1}
                  dataField="AutoPaymentRequestDDay"
                  editorType="dxNumberBox"
                  editorOptions={{
                    disabled: !isUsedAutoIssue,
                    min: 0,
                    max: 25,
                    format: 'D-# 일',
                    showSpinButtons: true,
                    showClearButton: true,
                    step: 1,
                  }}
                >
                  <Label text="자동 결제 요청일" />
                  <RequiredRule message="필수값 입니다." />
                </SimpleItem>
                <SimpleItem editorType="dxTextBox" cssClass="empty-item">
                  <Label text="* 월말일 기준" cssClass="padding-top-label" />
                </SimpleItem>
              </GroupItem>
            </Form>
          </ScrollView>
          <Form
          // formData={updateForm}
          // showColonAfterLabel={false}
          // labelMode="outside"
          // optionalMark="optional"
          // stylingMode="outlined"
          // labelLocation="left"
          >
            <GroupItem colCount={2}>
              <ButtonItem>
                <ButtonOptions width={200} type={'default'} onClick={onSave} text="저장"></ButtonOptions>
              </ButtonItem>
              <ButtonItem horizontalAlignment="left">
                <ButtonOptions width={200} type={'normal'} text="닫기" onClick={onClose}></ButtonOptions>
              </ButtonItem>
            </GroupItem>
          </Form>
        </div>
      </form>
      <CustomInfoPopup ref={customInfoRef} setSelectedCustomInfo={setSelectedCustomInfo} />
    </>
  );
};

export default React.memo(SeasonTicketMemberForm);
