import { React, useEffect, useMemo, useState, useRef } from 'react';
import { PivotGrid, FieldChooser, FieldPanel, Scrolling } from 'devextreme-react/pivot-grid';

import { LoadPanel as PSLoadPanel } from 'devextreme-react/load-panel';
import { Column, Summary, TotalItem } from 'devextreme-react/data-grid';
import { Lookup, DropDownOptions } from 'devextreme-react/lookup';
import { createWorksheet1 } from './worksheet/ws1';
import { createWorksheet2 } from './worksheet/ws2';
import { getNormalDateFormat } from 'utils/common';

import { CheckBox } from 'devextreme-react/check-box';
import { Button } from 'devextreme-react/button';
import { Workbook } from 'exceljs';
import { saveAs } from 'file-saver-es';
import { common } from 'api';

import PivotGridDataSource from 'devextreme/ui/pivot_grid/data_source';
import CommonDataGrid from 'common/default-data-grid';
import Toolbar, { Item } from 'devextreme-react/toolbar';
import CustomStore from 'devextreme/data/custom_store';
import DateBox from 'devextreme-react/date-box';
import moment from 'moment';
import axios from 'axios';

const userId = localStorage.getItem('userId');

const ParkingCostMonthlyReport = () => {
  const pivotgridRef = useRef({});
  const gridRef = useRef({});
  const sumBySalesGridRef = useRef({});
  const sumByPaymentCodeGridRef = useRef({});
  const [pivotDataSource, setPivotDataSource] = useState();
  const [costCenterType, setCostCenterType] = useState([]);
  const [costData, setCostData] = useState();
  const [salesDataSource, setSalesDataSource] = useState([]);
  const [sumBySalesDataSource, setSumDataSource] = useState([]);
  const [sumByPaymentCodeDataSource, setSumDataSource2] = useState([]);
  const [loadPanelVisible, setLoadPanelVisible] = useState(false);
  const [profitPartner, setProfitPartner] = useState(false);

  const [searchData, setSearchData] = useState({
    SalesMonth: moment().subtract(0, 'month').format('YYYY-MM'),
    CostCenterCode: '',
    ByPaymentDate: 'N',
    BySalesType: 'Y',
  });

  useEffect(() => {
    (async () => {
      const result = await common.costcenterData();
      if (result.isOk) {
        setCostCenterType(result.data);
      }
    })();
  }, []);

  useMemo(() => {
    if (costData) {
      const customDataSource = new CustomStore({
        loadMode: 'raw',
        load: async () => {
          return costData[1];
        },
      });
      const customSumDataSource = new CustomStore({
        loadMode: 'raw',
        load: async () => {
          return costData[2];
        },
      });
      const customSumDataSource2 = new CustomStore({
        loadMode: 'raw',
        load: async () => {
          return costData[3];
        },
      });
      const customPivotDataSource = new PivotGridDataSource({
        fields: [
          {
            dataField: 'SalesMonth',
            caption: '조회월',
            area: 'column',
          },
          {
            dataField: '구분1',
            expanded: true,
            area: 'row',
            width: 200,
          },
          {
            dataField: '구분2',
            area: 'row',
            width: 200,
          },
          {
            dataField: '총계',
            area: 'data',
            dataType: 'number',
            caption: 'Total',
            summaryType: 'sum',
            format: '#,##0',
          },
        ],

        store: new CustomStore({
          loadMode: 'raw',
          load: async () => {
            return costData[0];
          },
        }),
      });
      setPivotDataSource(customPivotDataSource);
      setSalesDataSource(customDataSource);
      setSumDataSource(customSumDataSource);
      setSumDataSource2(customSumDataSource2);
      setProfitPartner(costData[4][0]['ProfitRate-User']);
    }
  }, [costData]);

  const handleSalesMonth = e => {
    const salesMonth = moment(getNormalDateFormat(e.value)).format('YYYY-MM');
    setSearchData({
      ...searchData,
      SalesMonth: salesMonth,
    });
  };

  const onSearch = async () => {
    try {
      const { CostCenterCode, SalesMonth, ByPaymentDate, BySalesType } = searchData;
      setLoadPanelVisible(true);
      const costresult = await axios.post(`aggregation/getSalesFinalMonthlyReport`, {
        CostCenterCode,
        SalesMonth,
        ByPaymentDate,
        BySalesType,
        LoginID: userId,
      });
      setCostData(costresult.data);
    } catch (e) {
      const { type, title, status, detail, instance } = e.response.data;
      throw Error(JSON.stringify({ type, title, status, detail, instance }));
    }
    setLoadPanelVisible(false);
  };

  const onCellPrepared = e => {
    if (e.cellElement.classList.contains('dx-row-total') && e.cellElement.classList.contains('dx-grandtotal')) {
      const span = e.cellElement.querySelector('span');
      if (span && span.textContent === 'Grand Total') {
        span.textContent = '합계';
      }
    }
  };

  const onSelectionChanged = e => {
    const { CostCenterCode, CostCenterName } = e.selectedItem;
    const trimedCostCenterName = CostCenterName.split(']')[1].trim();
    setSearchData({ ...searchData, CostCenterCode, CostCenterName: trimedCostCenterName });
  };

  const onValueChanged = e => {
    const ByPaymentDate = e.value ? 'Y' : 'N';
    setSearchData({ ...searchData, ByPaymentDate });
  };

  const onTypeChanged = e => {
    const BySalesType = e.value ? 'Y' : 'N';
    setSearchData({ ...searchData, BySalesType });
  };

  const onExporting = async () => {
    if (!costData || costData.length === 0) {
      return alert('엑셀 데이터가 존재하지 않습니다.');
    }
    const workbook = new Workbook();
    const worksheet1 = workbook.addWorksheet('월손익현황');
    const worksheet2 = workbook.addWorksheet('매출상세내역');
    console.log(profitPartner);
    await createWorksheet1(worksheet1, searchData, pivotgridRef, profitPartner);
    await createWorksheet2(worksheet2, searchData, gridRef, sumBySalesGridRef, sumByPaymentCodeGridRef);
    try {
      const buffer = await workbook.xlsx.writeBuffer();
      saveAs(
        new Blob([buffer], { type: 'application/octet-stream' }),
        `수배정산서_${searchData.CostCenterName}_${searchData.SalesMonth}.xlsx`,
      );
    } catch (error) {
      console.error('Exporting error:', error);
    }
  };

  const hideLoadPanel = () => {
    setLoadPanelVisible(false);
  };

  return (
    <div>
      <div>
        <h2 className={'content-block'}>사업소 월별 운영 보고서</h2>
        <div className={'content-block'}>
          <div className={'dx-card responsive-paddings'}>
            <Toolbar>
              <Item location={'before'}>
                <div className="label">등록월 :</div>
              </Item>
              <Item location={'before'}>
                <DateBox
                  type="date"
                  pickerType="calendar"
                  displayFormat="yyyy-MM"
                  stylingMode="outlined"
                  width={90}
                  icon={true}
                  calendarOptions={{
                    maxZoomLevel: 'year',
                  }}
                  onValueChanged={handleSalesMonth}
                  value={searchData.SalesMonth}
                  elementAttr={{ id: 'search-date-box' }}
                  // label={'매출등록월'}
                />
              </Item>
              <Item location={'before'}>
                <Lookup
                  labelMode="static"
                  stylingMode="outlined"
                  dataSource={costCenterType}
                  valueExpr="CostCenterCode"
                  displayExpr="CostCenterName"
                  searchExpr="CostCenterName"
                  searchEnabled={true}
                  dropDownCentered={false}
                  width={300}
                  placeholder="검색어를 입력해주세요"
                  onSelectionChanged={onSelectionChanged}
                  mode="search"
                >
                  <DropDownOptions labelMode="static" stylingMode="outlined" showTitle={false} />
                </Lookup>
              </Item>
              <Item location={'after'}>
                <CheckBox defaultValue={true} text="매출유형(체크)/수금구분(해제)  " onValueChanged={onTypeChanged} />
                <CheckBox defaultValue={false} text="수금일 기준 조회   " onValueChanged={onValueChanged} />
                <Button
                  type="normal"
                  stylingMode="contained"
                  icon="search"
                  text="조회"
                  width={120}
                  onClick={onSearch}
                />
              </Item>
              <Item location={'after'}>
                <Button icon="exportxlsx" onClick={onExporting} />
              </Item>
            </Toolbar>
            <PivotGrid
              ref={pivotgridRef}
              className={'dx-card wide-card payment-pivot'}
              dataSource={pivotDataSource}
              showBorders={true}
              showColumnTotals={true}
              showRowTotals={true}
              showRowGrandTotals={false}
              hoverStateEnabled={true}
              height={700}
              onCellPrepared={onCellPrepared}
            >
              <Scrolling mode="virtual" showScrollbar="always" />
              <FieldPanel visible={true} allowFieldDragging={false} showFilterFields={false} />
              <FieldChooser enabled={false} />
            </PivotGrid>
            <CommonDataGrid
              gridRef={gridRef}
              className={'dx-card wide-card'}
              dataSource={salesDataSource}
              showBorders={true}
              hoverStateEnabled={true}
              stylingMode="outlined"
              isExcel={false}
              isHeader={false}
              visible={false}
            >
              <Scrolling mode="virtual" rowRenderingMode="virtual" />
              <Column dataField={'수금일'} width={'40'} />
              <Column dataField={'매출항목'} />
              <Column dataField={'매출항목상세'} />
              <Column dataField={'수금구분'} />
              <Column dataField={'매출수량'} />
              <Column dataField={'합계금액'} format="#,##0" />
              <Summary>
                <TotalItem column="수금구분" displayFormat="수금구분" />
                <TotalItem column="매출수량" summaryType="sum" valueFormat="#,##0" displayFormat="{0}" />
                <TotalItem
                  column="합계금액"
                  summaryType="sum"
                  valueFormat="#,##0 원"
                  displayFormat="{0}"
                  alignment="right"
                />
              </Summary>
            </CommonDataGrid>
            <CommonDataGrid
              gridRef={sumBySalesGridRef}
              className={'dx-card wide-card'}
              dataSource={sumBySalesDataSource}
              showBorders={true}
              hoverStateEnabled={true}
              stylingMode="outlined"
              isExcel={false}
              isHeader={false}
              visible={false}
            >
              <Scrolling mode="virtual" rowRenderingMode="virtual" />
              <Column dataField={'매출항목'} />
              <Column dataField={'금액'} format="#,##0 원" />
              <Summary>
                {/* <TotalItem column="매출항목" /> */}
                <TotalItem column="금액" summaryType="sum" valueFormat="#,##0" displayFormat="{0}" />
                <TotalItem
                  column="합계금액"
                  summaryType="sum"
                  valueFormat="#,##0 원"
                  displayFormat="{0}"
                  alignment="right"
                />
              </Summary>
            </CommonDataGrid>
            <CommonDataGrid
              gridRef={sumByPaymentCodeGridRef}
              className={'dx-card wide-card'}
              dataSource={sumByPaymentCodeDataSource}
              showBorders={true}
              hoverStateEnabled={true}
              stylingMode="outlined"
              isExcel={false}
              isHeader={false}
              visible={false}
            >
              <Scrolling mode="virtual" rowRenderingMode="virtual" />
              <Column dataField={'수금구분'} />
              <Column dataField={'금액'} format="#,##0 원" />
              <Summary>
                <TotalItem column="금액" summaryType="sum" valueFormat="#,##0" displayFormat="{0}" />
                <TotalItem
                  column="합계금액"
                  summaryType="sum"
                  valueFormat="#,##0 원"
                  displayFormat="{0}"
                  alignment="right"
                />
              </Summary>
            </CommonDataGrid>
          </div>
          <div></div>
        </div>
        <PSLoadPanel
          shadingColor="rgba(0,0,0,0.4)"
          position={{ of: '.content' }}
          onHiding={hideLoadPanel}
          visible={loadPanelVisible}
        />
      </div>
    </div>
  );
};

export default ParkingCostMonthlyReport;
